import './App.css';
import Profile from './components/Profile';

function App() {
  return (
      <Profile />
  );
}

export default App;
